/**
 * Use JS to create a typewriter animation.
 *
 * Requires you to provide two key parameters:
 * - ID of the element to update text for
 * - ID of the element that will represent the "cursor"
 */

/**
 * Given an array of strings, return flattened array of all substrings.
 */
export function getSubstringArray(texts: string[]): string[] {
  const substrings: string[] = []
  for (const text of texts) {
    let curSubstring = ''
    substrings.push(curSubstring)
    for (const char of text) {
      curSubstring += char
      substrings.push(curSubstring)
    }
  }
  return substrings
}

/**
 * Use `useInterval` to alternate between an "on" transformation and an "off"
 * transformation.
 *
 * `alternateTimeMs` parameterizes the length of the interval.
 */
function applyCursorEffect(
  targetElement: HTMLElement,
  turnOn: (element: HTMLElement) => void,
  turnOff: (element: HTMLElement) => void,
  alternateMs: number,
): number {
  let ix = 0

  // @ts-ignore - uses nodejs type instead of browser type
  return setInterval(() => {
    const action = ix === 0 ? turnOff : turnOn
    action(targetElement)
    ix = ix === 0 ? 1 : 0
  }, alternateMs)
}

interface TypewriterWaitTimes {
  // time between characters in the same text string
  characterWaitMs: number
  // time to wait after completing each text string
  textWaitMs: number
  // how quickly the cursor should "blink"
  cursorAlternateMs: number
}

/**
 * Animate text with a "typewriter" effect by
 * (a) updating the "target text element"'s innerText
 * (b) blinking the cursor as we wait for the next substring
 */
export function applyTypewriterEffect(
  targetTextElementId: string,
  targetCursorElementId: string,
  displayTexts: string[],
  cursorOn: (e: HTMLElement) => void,
  cursorOff: (e: HTMLElement) => void,
  timeOptions: TypewriterWaitTimes,
): void {
  // Validate input
  if (displayTexts.length === 0) {
    return
  }

  if (displayTexts.some((x) => !x)) {
    throw new Error(
      'useTypewriterEffect: all display texts must be non-null / non-empty',
    )
  }

  const targetTextElement = document.getElementById(targetTextElementId)
  if (!targetTextElement) {
    throw new Error('useTypewriterEffect: Unable to find target text element')
  }

  const targetCursorElement = document.getElementById(targetCursorElementId)
  if (!targetCursorElement) {
    throw new Error('useTypewriterEffect: Unable to find target cursor element')
  }

  // Get all substrings of the display texts
  const displaySubstrings: string[] = getSubstringArray(displayTexts)
  const nSubstrings = displaySubstrings.length

  // Define a recursive function to do the animation by looping through the
  // substrings
  function setDisplaySubstringAndQueueNext(curIx: number) {
    cursorOn(targetCursorElement)

    const curString = displaySubstrings[curIx]
    targetTextElement.innerText = curString

    const nextIx = (curIx + 1) % nSubstrings
    const nextString = displaySubstrings[nextIx]

    // Start cursor blink
    const intervalId = applyCursorEffect(
      targetCursorElement,
      cursorOn,
      cursorOff,
      timeOptions.cursorAlternateMs,
    )

    if (nextString.length > curString.length) {
      setTimeout(() => {
        clearInterval(intervalId)
        setDisplaySubstringAndQueueNext(nextIx)
      }, timeOptions.characterWaitMs)
    } else {
      setTimeout(() => {
        clearInterval(intervalId)
        setDisplaySubstringAndQueueNext(nextIx)
      }, timeOptions.textWaitMs)
    }
  }

  setDisplaySubstringAndQueueNext(0)
}
